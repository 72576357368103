import React, {Fragment, useEffect, useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon, SearchIcon} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {getDatetimeString} from "../utils";
import {Dialog, Transition} from '@headlessui/react'
import {useLocation} from "react-router-dom"

function documentRefLink(ref) {
    try {
        let url = "", name, value
        const segments = ref?._path?.segments || []
        if(segments[2] === "promissory-notes" && segments[3]) {
            name= "promissoryNote"
            value=segments[3]
            url = `${segments[2]}/${segments[3]}`
        } else if(segments[0] === "billing-transactions" && segments[1]) {
            name= "debtorPayment"
            value=segments[1]
            url = `${segments[0]}/${segments[1]}`
        }
        if(url) return <a href={"#" + url} className="underline">{name}#<span className="font-medium">{value}</span></a>
    }catch (e) {

    }
    return null
}

const defaultColumns = [
        {
            v: d => (d.timestamp?._seconds ? getDatetimeString(new Date(d.timestamp?._seconds * 1000)) : "-"),
            label: "Timestamp",
            sort: "timestamp"
        },
        {v: d => d.status, label: "Status", sort: "status"},
        {v: d => d.action, label: "Action", sort: "action"},
        {v: d => d.message || "-", label: "Message", sort: "message"},
        {v: d => documentRefLink(d.documentRef) || "-", label: "Reference", sort: "documentRef"},
    ],
    defaultOrderBy = "timestamp",
    defaultOrderByDirection = "desc"

const limit = 20;


export const LogList = (props) => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [offset, setOffset] = useState(0)
    const logout = useLogout();

    const location = useLocation()
    const query = new URLSearchParams(location?.search || ""),
        d = query.get("documentation")

    const [isFilterOn, setIsFilterOn] = useState(!!d)
    const [filterBy, setFilterBy] = useState(d || null)

    useEffect(() => {
        fetchList()
    }, [orderBy, orderByDirection, offset, isFilterOn, filterBy])

    useEffect(() => {
        const query = new URLSearchParams(location?.search || ""),
            d = query.get("documentation")
        if (d) {
            setFilterBy(d)
            setIsFilterOn(true)
        } else {
            setFilterBy(null)
            setIsFilterOn(false)
        }
    }, [location?.search])

    function fetchList() {
        sharedFetch(`admin/logs?limit=${limit}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}${isFilterOn ? "&promissoryNoteId=" + (filterBy || "") : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then((json) => {
                setResult(json)
            })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
                setOffset(0)
            }
        }
    }

    function toggleFilter(e) {
        e.preventDefault();
        //const isFilterOnNewState = !isFilterOn
        /*setFilterBy(null)
        setIsFilterOn(false)*/
        window.location.hash = "#/logs"
    }

    const [isInfoOpen, setIsInfoOpen] = useState(false)
    const [infoContent, setInfoContent] = useState(null)

    function showInfo(json) {
        setInfoContent(json)
        setIsInfoOpen(true)
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Logs</h1>
                            {/*<p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>*/}
                            <div className="sm:px-8 inline-flex">
                                {isFilterOn && (
                                    <button
                                        type="button"
                                        onClick={toggleFilter}
                                        className={classNames(
                                            "inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2",
                                            isFilterOn ? "pl-2.5 pr-1 border-current" : "pr-2.5 pl-2.5 border-transparent",
                                            "bg-red-100 text-red-700"
                                        )}>

                                        Filtered by: {filterBy || "-"}
                                        <button
                                            type="button"
                                            onClick={toggleFilter}
                                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-400 hover:bg-red-200 hover:text-red-500 focus:outline-none focus:bg-red-500 focus:text-white"
                                        >
                                            <span className="sr-only">Remove overdue filter</span>
                                            <svg className="h-2 w-2" stroke="currentColor" fill="none"
                                                 viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                                            </svg>
                                        </button>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {/*<a
                                type="button"
                                href="#/documentation/create"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </a>*/}
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex truncate",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Info</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.results?.map(data => (
                                            <tr key={data.id}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            "whitespace-nowrap text-sm truncate",
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        {col.v(data)}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button
                                                        type="button"
                                                        onClick={() => showInfo(JSON.stringify(data.meta, undefined, 2))}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <SearchIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span
                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                className="font-medium">{result?.offset + result?.count || "0"}</span>
                                                {!isFilterOn && (<>
                                                    {' '}of{' '}
                                                    <span className="font-medium">{result?.total || "0"}</span> results
                                                    total
                                                </>)}
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition.Root show={isInfoOpen} as={Fragment} appear>
                <Dialog as="div" className="relative z-10" onClose={setIsInfoOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                                <div className="bg-gray-50 p-8">
                                    <pre
                                        className="bg-gray-100 p-2 text-blue-600 text-sm rounded-md overflow-hidden"><span
                                        className="text-xs text-gray-400">JSON</span><br/>{infoContent}</pre>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};
