import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
    classNames,
    dateFormatter,
    parseNumberString,
    sharedAccessTokenKey,
    sharedFetch,
    sloDateParser
} from "../helpers/helpers";
import {
    DocumentRemoveIcon,
    DotsVerticalIcon,
    ExclamationIcon,
    InformationCircleIcon,
    PaperClipIcon,
    PlusSmIcon as PlusSmIconSolid,
    UploadIcon,
    ViewListIcon
} from '@heroicons/react/solid'
import {Menu, Transition} from '@headlessui/react'
import {useLogout} from "react-admin";
import {
    chunkify,
    downloadFile,
    downloadStorageFile,
    fireTimestampToDateInputString,
    fireTimestampToDateTimeString,
    inputDateStringToISODateFormat,
    slugify,
    validReferenceNumber
} from "../utils";
import {PROMISSORY_NOTE_STATUS, PROMISSORY_NOTE_STATUS_KEY, TYPE} from "./consts";
import Spinner from "../components/spinner";
import FactoringPricingSummarySection from "./factoringPricingSummarySection";
import UploadFileDialog from "./uploadFileDialog";
import {DataTable} from "./dataTable";
import EmailNotificationDialog, {
    EMAIL_FACTORING_DRAFT_ASSIGNEE,
    EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR
} from "./emailNotificationDialog";
import PartySelector from "./partySelector";
import SavingNotification from "../components/savingNotification";
import MaxDialog from "./maxDialog";
import currency from "currency.js";
import {useBilling} from "../billing/list";
import {downloadBilling} from "../billing/utils";
import BillOfExpensesDialog from "./billOfExpensesDialog";

const documentTypes = {
    "UNSIGNED_DOCUMENT": "Unsigned Document",
    "SIGNED_BY_ASSIGNEE": "Signed by Supplier/Creditor",
    "SIGNED_BY_DEBTOR": "Signed by Buyer/Debtor",
    "SIGNED_BY_ALL_PARTIES": "Signed by All Parties",
}

function investmentCompanyToOrganizationDataFieldMap(data) {
    if (!data) return null
    const bankAccount = data.bankAccounts?.filter(ba => ba.main === true)[0]

    return {
        oid: "" + data.id,
        name: data.shortName || data.name,
        bankAccount: {
            bankName: bankAccount.bank,
            number: bankAccount.accountNumber,
        },
        taxNo: data.taxNumber,
        address: {
            address1: data.address,
            postCode: data.postCode,
            city: data.city,
            country: data.country
        }
    }
}

function FileItem({title, text, onDownload, archived}) {
    const [isDownloading, setIsDownloading] = useState(false)

    function onDownloadClick(e) {
        e.preventDefault()

        if (isDownloading) return

        setIsDownloading(true)

        onDownload(() => setIsDownloading(false))
    }


    return <li className={classNames(
        "flex items-center justify-between py-3 pl-3 pr-4 text-sm",
        archived ? "bg-gray-50 opacity-60" : "bg-white"
    )}>
        <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"/>
            <div className="ml-3 w-0 flex-1 truncate">
                <p className="text-sm font-medium text-gray-900">{archived && (
                    <span
                        className="inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">Archived</span>
                )} {title}</p>
                <p className="text-sm text-gray-500">{text}</p>
            </div>
            {/*<span className="ml-2 w-0 flex-1 truncate">Unsigned Contract Draft</span>*/}
        </div>
        <div className="ml-4 flex flex-shrink-0 space-x-4">
            <button
                type="button"
                onClick={onDownloadClick}
                disabled={isDownloading}
                className={classNames(
                    "rounded-md bg-white font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2",
                    isDownloading ? "cursor-default" : "cursor-pointer"
                )}
            >
                {isDownloading ? (<Spinner/>) : "Download"}
            </button>
            {/*<span className="text-gray-300" aria-hidden="true"> | </span>
                                                            <button
                                                                type="button"
                                                                className="rounded-md bg-white font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                                            >
                                                                Remove
                                                            </button>*/}
        </div>
    </li>
}

function ExtendedFileItem({title, text, onDownload, archived}) {
    const [isDownloading, setIsDownloading] = useState(false)

    function onClickListener(extended) {
        return function onDownloadClick(e) {
            e.preventDefault()

            if (isDownloading) return

            setIsDownloading(true)

            onDownload(extended, () => setIsDownloading(false))
        }
    }


    return <li className={classNames(
        "flex items-center justify-between py-3 pl-3 pr-4 text-sm",
        archived ? "bg-gray-50 opacity-60" : "bg-white"
    )}>
        <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"/>
            <div className="ml-3 w-0 flex-1 truncate">
                <p className="text-sm font-medium text-gray-900">{archived && (
                    <span
                        className="inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">Archived</span>
                )} {title}</p>
                <p className="text-sm text-gray-500">{text}</p>
            </div>
            {/*<span className="ml-2 w-0 flex-1 truncate">Unsigned Contract Draft</span>*/}
        </div>
        <div className="relative ml-8 flex flex-shrink-0 space-x-4">
            <div className="absolute -ml-6 -top-2 flex items-center justify-center">
                {isDownloading && (<Spinner/>)}
            </div>
            <button
                type="button"
                onClick={onClickListener()}
                disabled={isDownloading}
                className={classNames(
                    "rounded-md bg-white font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2",
                    isDownloading ? "cursor-default" : "cursor-pointer"
                )}
            >
                Download Single PDF
            </button>
            <span className="text-gray-300" aria-hidden="true"> | </span>
            <button
                type="button"
                onClick={onClickListener(true)}
                disabled={isDownloading}
                className={classNames(
                    "rounded-md bg-white font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2",
                    isDownloading ? "cursor-default" : "cursor-pointer"
                )}
            >
                Multiple PDFs
            </button>
        </div>
    </li>
}

const today = (new Date()).toLocaleDateString('en-CA');

export default function PromissoryNote({match}) {
    const logout = useLogout();
    const importRef = useRef()
    const [id, setId] = useState(); // entry editing
    const [canEdit, setCanEdit] = useState(true)
    const [isLoading, setIsLoading] = useState(false); // entry editing
    const [emailNotificationDialogOpen, setEmailNotificationDialogOpen] = useState(false) // false || <template>
    const [expensesDialogOpen, setExpensesDialogOpen] = useState(false)
    const [maxDialogOpen, setMaxDialogOpen] = useState(false)
    const [serverData, setServerData] = useState(null)
    const [status, setStatus] = useState(PROMISSORY_NOTE_STATUS_KEY.DRAFT)
    const [type, setType] = useState("")
    const [documentNo, setDocumentNo] = useState("")
    const [date, setDate] = useState(today)
    const [transactionDate, setTransactionDate] = useState(today)
    const [documents, setDocuments] = useState([])
    const [debtorOid, setDebtorOid] = useState("")
    const [assigneeOid, setAssigneeOid] = useState("")
    const [debtorMaxExtensionPeriod, setDebtorMaxExtensionPeriod] = useState(0); // maxExtensionPeriod
    const [assigneeBankAccount, setAssigneeBankAccount] = useState(null)
    const [factoringPricing, setFactoringPricing] = useState(null)
    const [invoiceRows, setInvoiceRows] = useState([])
    const [originalInvoiceMap, setOriginalInvoiceMap] = useState({})
    const [infoCosts, setInfoCosts] = useState([])
    const [addInvoices, setAddInvoices] = useState([])
    const [updateInvoices, setUpdateInvoices] = useState([])
    const [deleteInvoices, setDeleteInvoices] = useState([]) // array of invoice ids
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmittingInvoices, setIsSubmittingInvoices] = useState(false)
    const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [companies, setCompanies] = useState([])
    const {
        setOrderBy,
        setOrderByDirection,
        result,
        nextPage,
        prevPage,
    } = useBilling("app/finance/promissory-notes/" + match.params?.id)

    //console.log("result", result)

    useEffect(() => {
        const id = match.params?.id
        setId(id)
        fetchData(id)
        fetchInvoices(id)
        fetchCompanies()
    }, [match.params?.id]);

    const invoiceUpdatesAwaiting = addInvoices?.length > 0 || updateInvoices?.length > 0 || deleteInvoices?.length > 0
    const updatesAwaiting = serverData?.status !== status || serverData?.type !== type || serverData?.number !== documentNo || fireTimestampToDateInputString(serverData?.date) !== date || fireTimestampToDateInputString(serverData?.transactionDate) !== transactionDate;
    const criticalLogs = serverData?.logs?.filter(log => log?.level === "critical") || []
    const warningLogs = serverData?.logs?.filter(log => log?.level === "warning") || []
    const infoLogs = serverData?.logs?.filter(log => log?.level === "info") || []

    function fetchInvoices(id) {
        try {
            sharedFetch(`admin/finance/promissory-notes/${id}/invoices`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                },
            })
                .then((resData) => {
                    if (resData?.statusCode === 401) return logout()

                    if (resData?.results?.length > 0) {
                        const _invoiceRows = resData.results.map(r => ({
                            ...r,
                            id: r.id,
                            amount: (r.amount?.value || 0),
                            paidAmount: (r.paidAmount?.value || 0),
                            date: fireTimestampToDateInputString(r.date),
                            dueDate: fireTimestampToDateInputString(r.dueDate),
                            extendedPaymentDate: fireTimestampToDateInputString(r.extendedPaymentDate),
                            repaidDate: fireTimestampToDateInputString(r.repaidDate),
                        }))
                        setInvoiceRows([..._invoiceRows].sort(invoiceRowsSorter))
                        setOriginalInvoiceMap(_invoiceRows.reduce((map, obj) => {
                            map[obj.id] = obj
                            return map
                        }, {}))
                    } else {
                        setInvoiceRows([])
                        setOriginalInvoiceMap({})
                    }
                })
        } catch (e) {
        }
    }

    function fetchData(id) {
        if (id && !isSubmitting) {
            !serverData && setIsLoading(true)
            try {
                sharedFetch(`admin/finance/promissory-notes/${id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                    },
                })
                    .then((resData) => {
                        if (resData?.statusCode === 401) return logout()

                        const documentsTrackMap = {},
                            _documents = (resData?.documents || []).reverse().map(document => {
                                if (documentsTrackMap[document.type]) document.overridden = true
                                documentsTrackMap[document.type] = true
                                return document
                            }).reverse()

                        if (serverData) {
                            setDocumentNo(resData.number)
                            setDocuments(_documents)
                            setDebtorOid(resData.debtor?.oid || "")
                            setAssigneeOid(resData.assignee?.oid || "")
                            setDebtorMaxExtensionPeriod(resData.maxExtensionPeriod || 0)
                            setFactoringPricing(resData.factoringPricing || null)

                            if (!status) setStatus(resData.status)
                            if (!date) setDate(fireTimestampToDateInputString(resData.date))
                            if (!transactionDate) setTransactionDate(fireTimestampToDateInputString(resData.transactionDate))

                        } else {
                            setStatus(resData.status)
                            setType(resData.type || "")
                            setDocumentNo(resData.number)
                            setDate(fireTimestampToDateInputString(resData.date))
                            setTransactionDate(fireTimestampToDateInputString(resData.transactionDate))
                            setDocuments(_documents)
                            setDebtorOid(resData.debtor?.oid || "")
                            setAssigneeOid(resData.assignee?.oid || "")
                            setDebtorMaxExtensionPeriod(resData.maxExtensionPeriod || 0)
                            setFactoringPricing(resData.factoringPricing || null)
                        }
                        setServerData(resData)
                        setIsLoading(false)
                    });
            } catch (e) {
                setIsLoading(false)
            }
        }
    }

    function onChangeParty(partyType, party, _callback) {
        const cb = (ok, error) => {
                if (_callback) _callback(ok, error)
                ok && id && fetchData(id)
            },
            updates = {}
        if (partyType === "debtor") {
            updates.debtorOid = "" + party.id
            setDebtorOid(updates.debtorOid)
        } else if (partyType === "assignee") {
            const _assignee = investmentCompanyToOrganizationDataFieldMap(party)
            updates.assigneeOid = "" + party.id
            setAssigneeOid(updates.assigneeOid)
            if (_assignee?.bankAccount?.number) {
                updates.assigneeBankAccount = {
                    bankName: _assignee.bankAccount.bankName || null,
                    number: _assignee.bankAccount.number
                }
                setAssigneeBankAccount({...updates.assigneeBankAccount})
            }
        } else return cb(false)

        if (id) onSubmit(false, null, updates).then(() => cb(true))
        else cb(true)
    }

    function fetchInfoCosts() {
        if (type && debtorOid && transactionDate && invoiceRows?.length > 0) {
            try {
                const object = prepareSubmitData(false);
                if (!object) return
                sharedFetch(`promissory-notes/costs`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                    },
                    body: JSON.stringify({
                        type: object.type,
                        debtorOid: object.debtorOid,
                        assigneeOid: object.assigneeOid,
                        transactionDate: object.transactionDate,
                        invoices: object.invoices,
                    })
                })
                    .then(data => {
                        if (data?.statusCode === 401) return logout()

                        //const {formInput, invoices, signedByDebtor, signedByRessignee, isProbablyOverdue} = resData
                        //console.log("info costs", data)
                        data && setInfoCosts(data)
                    });
            } catch (e) {
            }
        } else {
            setInfoCosts([])
        }
    }

    async function onImport() {
        if (importRef?.current?.files?.length > 0) {
            try {
                const XLSX = await import("xlsx")

                const data = await importRef.current.files[0].arrayBuffer();

                const workbook = XLSX.read(data, {type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy;@'}),
                    sheetKeys = Object.keys(workbook.Sheets),
                    ws = workbook.Sheets[sheetKeys[0]]

                if (sheetKeys?.length > 1) alert("You're importing a document with multiple sheets. Only the first sheet will be imported!")

                const jsa = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false/*, rawNumbers: true*/});

                const importedRows = [], importedIds = []
                for (let i = 0; i < jsa?.length; i++) {
                    let [_isProforma, _number, _date, _dueDate, _extendedPaymentDate, _amount] = jsa[i]
                    if (!_number) continue

                    const _isProformaString = String(_isProforma).toLowerCase()
                    const _isProformaON = _isProformaString === "on" || _isProformaString === "yes" || _isProformaString === "true" || _isProformaString === "proforma"
                    if (!_amount && !_isProformaON) [_number, _date, _dueDate, _extendedPaymentDate, _amount] = jsa[i]

                    console.log("got amount", _amount)

                    const invoiceId = "" + (i + Date.now())
                    importedRows.push({
                        fictional: true,
                        changed: true,
                        id: invoiceId,
                        number: _number,
                        isProforma: _isProformaON,
                        date: _date ? dateFormatter(sloDateParser(_date).getTime()) : "",
                        dueDate: _dueDate ? dateFormatter(sloDateParser(_dueDate).getTime()) : "",
                        extendedPaymentDate: _extendedPaymentDate ? dateFormatter(sloDateParser(_extendedPaymentDate).getTime()) : "",
                        amount: (parseNumberString(_amount) || "")
                    })
                    importedIds.push(invoiceId)
                }

                setInvoiceRows([
                    ...importedRows,
                    ...invoiceRows,
                ].sort(invoiceRowsSorter))
                setAddInvoices([...importedIds, ...addInvoices])
            } catch (e) {
                console.error(e)
            }
            importRef.current.value = ""
        }
    }

    function addRow() {
        const invoiceId = "" + Date.now()
        setInvoiceRows(invoiceRows => [{
            fictional: true,
            changed: true,
            id: invoiceId,
            number: "",
            isProforma: false,
            date: "",
            dueDate: "",
            extendedPaymentDate: "",
            amount: ""
        }, ...invoiceRows].sort(invoiceRowsSorter).map((it, i) => {
            console.log("Added", i, it)
            return it
        }))
        setAddInvoices([invoiceId, ...addInvoices])
    }

    function deleteRow(id) {
        setInvoiceRows(invoiceRows.filter(row => {
            const shouldRemove = row.id === id
            if (shouldRemove) {
                if (row.fictional === true) {
                    setAddInvoices(addInvoices.filter(invoiceId => invoiceId !== id))
                } else if (deleteInvoices.indexOf(id) === -1) {
                    setDeleteInvoices([...deleteInvoices, id])
                }
            }

            return !shouldRemove
        }).sort(invoiceRowsSorter))
    }

    function updateRow(id, fn) {
        //console.log("updateRow", data)
        setInvoiceRows(invoiceRows => invoiceRows.map(row => {
            if (row.id === id) {
                const data = fn(row)
                if (data.fictional !== true && data.changed === true) {
                    // only rows that have non-fictional id and are changed can be updated; fictional rows are only added
                    setUpdateInvoices(updateInvoices => [...new Set([data.id, ...updateInvoices])])
                } else {
                    // remove from changed list
                    setUpdateInvoices(updateInvoices => updateInvoices.filter(invoiceId => invoiceId !== data.id))
                }
                return data
            }
            return row
        }).sort(invoiceRowsSorter))
    }

    function prepareSubmitData(showAlerts = true, emailTemplate, updates = {}) {
        const object = id ? {
            type,
            number: documentNo,
            date: inputDateStringToISODateFormat(date) || undefined,
            transactionDate: inputDateStringToISODateFormat(transactionDate) || undefined,
            emailTemplate: emailTemplate || undefined,
            ...updates
        } : {
            type,
            date: inputDateStringToISODateFormat(date) || undefined,
            transactionDate: inputDateStringToISODateFormat(transactionDate) || undefined,
            emailTemplate: emailTemplate || undefined,
            ...updates
        };

        if (!updates?.debtorOid && debtorOid && debtorOid !== serverData?.debtor?.oid) object.debtorOid = debtorOid
        if (!updates?.assigneeOid && assigneeOid && assigneeOid !== serverData?.assignee?.oid) object.assigneeOid = assigneeOid
        if (!updates?.assigneeBankAccount && assigneeBankAccount) object.assigneeBankAccount = assigneeBankAccount

        if (!id) {
            if (documentNo) object.number = documentNo
            object.invoices = invoiceRows?.length ? invoiceRows.map(row => {
                const invoice = {
                        number: row.number,
                        isProforma: row.isProforma,
                        amount: row.amount,
                        status: row.status,
                        invoiceType: type === "RF" ? "RECEIVED" : "ISSUED",
                    },
                    date = inputDateStringToISODateFormat(row.date),
                    dueDate = inputDateStringToISODateFormat(row.dueDate)
                if (!date || !dueDate) {
                    if (showAlerts) alert("You must fill in all invoice dates!")
                    return null
                }
                if (row.extendedPaymentDate) {
                    const epd = inputDateStringToISODateFormat(row.extendedPaymentDate)
                    if (epd) invoice.extendedPaymentDate = epd
                }
                return {...invoice, date, dueDate}
            }) : []
        }

        return object
    }

    function canSubmit() {
        if (isSubmitting) {
            alert("Please wait for the form to finish submitting before saving changes")
            return false
        }
        if (isSubmittingInvoices) {
            alert("Please wait for the invoices to finish submitting before saving changes")
            return false
        }
        if (uploading) {
            alert("Please wait for the upload to complete before saving changes")
            return false
        }

        return true
    }

    function openSendEmailDialog() {
        setEmailNotificationDialogOpen(type === "RF" ? EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR : EMAIL_FACTORING_DRAFT_ASSIGNEE)
    }

    async function onSendDocumentsForSigning(e, emailTemplate) {
        e && e.preventDefault();

        if (!canSubmit()) return

        /*if (e && id && serverData?.status === PROMISSORY_NOTE_STATUS_KEY.PENDING_APPROVAL && type) {
            setEmailNotificationDialogOpen(type === "RF" ? EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR : EMAIL_FACTORING_DRAFT_ASSIGNEE)
            return
        }*/

        setIsSubmitting(true)

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify({
                emailTemplate
            })

        await sharedFetch(`admin/finance/promissory-notes/${id}/send`, {
            method: 'POST',
            headers, body
        })
            .then(data => {
                if (data?.statusCode === 401) return logout()

                if (data?.message) {
                    alert(data.message)
                } else if (data.ok) {
                    //goBack()
                    if (data.id) {
                        goTo(data.id)
                    } else if (id) fetchData(id)
                    else goTo()
                }
            }).catch(e => {
                if (e.status === 401) {
                    logout();
                }
            }).finally(() => {
                setIsSubmitting(false)
            })
    }

    function invoiceRowsSorter(a, b) {
        /*if (invoiceUpdatesAwaiting) return 0 */
        if (a.fictional && b.fictional) {
            return a.id?.localeCompare(b.id) || 0
        }
        if (a.fictional) return 1
        if (b.fictional) return -1
        const aepd = a.extendedPaymentDate || a.dueDate,
            bepd = b.extendedPaymentDate || b.dueDate
        if (aepd === bepd) {
            if (a.dueDate === b.dueDate) {
                return a.number?.localeCompare(b.number) || 0
            }
            return a.dueDate?.localeCompare(b.dueDate) || 0
        }
        return aepd?.localeCompare(bepd) || 0
    }

    async function onExpensesDialogSubmit(data) {
        console.log("DATA", data)
    }

    async function onMaxDialogSubmit(data) {
        const {paidAmount, paidAt} = data
        setMaxDialogOpen(false)
        if (!paidAmount || !paidAt) return

        let paidAmountLeft = currency(paidAmount)

        const jobId = Date.now()

        //console.log("PAID AMOUNT", paidAmount)

        setInvoiceRows(invoiceRows => {
            // sort by extension date (najstarejši proti najml), potem due date, potem number
            const changes = invoiceRows.map(inv => {
                const {amount, financedAmount, repaidAmount} = inv,
                    invoiceFinancedAmountValue = financedAmount ? financedAmount.value : amount,
                    invoiceOpenAmount = currency(invoiceFinancedAmountValue).subtract(repaidAmount?.value || 0)

                if (!inv.fictional && invoiceFinancedAmountValue > (repaidAmount?.value || 0) && invoiceOpenAmount.intValue > 0 && paidAmountLeft.intValue > 0) {
                    const _paid = {
                        paidAmount: inv._paid?.paidAmount || 0,
                        paidDate: paidAt,
                        jobId: jobId
                    }
                    inv._paid = _paid;
                    if (inv._paid.paidAmount < invoiceOpenAmount.value) {
                        if (invoiceOpenAmount.value >= paidAmountLeft.value) {
                            inv._paid.paidAmount = currency(inv._paid.paidAmount).add(paidAmountLeft.value).value
                            paidAmountLeft = currency(0)
                        } else {
                            inv._paid.paidAmount = currency(inv._paid.paidAmount).add(invoiceOpenAmount.value).value
                            paidAmountLeft = paidAmountLeft.subtract(invoiceOpenAmount)
                        }
                    } // else alert("Invoice #" + inv.number + " open amount is " + sum.value + "€ but the repaid amount is " + inv._paid.paidAmount + "€")
                }
                return {...inv}
            }).sort(invoiceRowsSorter)


            //console.log({paidAmount, paidAt, paidAmountLeft})
            if (paidAmountLeft.value > 0) alert("The amount of " + paidAmountLeft.value + "€ could not be assigned to any invoices")
            return changes
        })
    }

    async function onSubmit(e, emailTemplate, updates = {}) {
        e && e.preventDefault();

        if (!canSubmit()) return

        /*if (e && id && serverData?.status === PROMISSORY_NOTE_STATUS_KEY.PENDING_APPROVAL && type) {
            setEmailNotificationDialogOpen(type === "RF" ? EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR : EMAIL_FACTORING_DRAFT_ASSIGNEE)
            return
        }*/

        const object = prepareSubmitData(true, emailTemplate, updates)
        if (!object) return

        setIsSubmitting(true)

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        await sharedFetch(`admin/finance/promissory-notes${id ? ("/" + id) : ""}`, id ? {
            method: 'PUT',
            headers, body
        } : {
            method: 'POST',
            headers, body
        })
            .then(data => {
                if (data?.message) {
                    alert(data.message)
                } else if (data.ok) {
                    //goBack()
                    if (data.id) {
                        goTo(data.id)
                    } else if (id) fetchData(id)
                    else goTo()
                }
            }).catch(e => {
                if (e.status === 401) {
                    logout();
                }
            }).finally(() => {
                setIsSubmitting(false)
            })
    }

    function goTo(id) {
        const link = document.createElement('a');
        link.href = "#/promissory-notes/" + id;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    async function submitInvoices() {
        if (!canSubmit()) return

        if (invoiceUpdatesAwaiting) {
            setIsSubmittingInvoices(true)

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            }

            try {

                async function start() {
                    if (addInvoices?.length > 0) {
                        let error
                        const invoices = invoiceRows.filter(inv => addInvoices.includes(inv.id)).map(row => {
                                const invoice = {
                                        number: row.number,
                                        isProforma: row.isProforma,
                                        amount: row.amount,
                                        status: row.status,
                                        invoiceType: type === "RF" ? "RECEIVED" : "ISSUED",
                                    },
                                    date = inputDateStringToISODateFormat(row.date),
                                    dueDate = inputDateStringToISODateFormat(row.dueDate)
                                if (!date || !dueDate) {
                                    error = "You must fill in all invoice dates!"
                                }
                                if (row.extendedPaymentDate) {
                                    const epd = inputDateStringToISODateFormat(row.extendedPaymentDate)
                                    if (epd) invoice.extendedPaymentDate = epd
                                }
                                return {...invoice, date, dueDate}
                            }),
                            chunks = chunkify(invoices, 100)

                        if (invoices?.length !== addInvoices?.length) {
                            alert("Something went wrong, please refresh the page and try again!")
                            return
                        }

                        if (error) {
                            alert(error)
                            return
                        }

                        for (let i = 0; i < chunks?.length; i++) {
                            const chunk = chunks[i]
                            await sharedFetch(`admin/finance/promissory-notes/${id}/invoices`, {
                                method: 'POST',
                                headers, body: JSON.stringify({invoices: chunk})
                            }).catch(e => {
                                if (e?.message) {
                                    alert("Error adding invoices: " + e.message)
                                } else {
                                    alert("There was an error adding invoices!")
                                }
                                throw e
                            })
                        }
                    }

                    if (updateInvoices?.length > 0) {
                        let error
                        const invoices = invoiceRows.filter(inv => updateInvoices.includes(inv.id)).map(row => {
                                const invoice = {
                                    id: row.id,
                                    //number: row.number,
                                    //amount: row.amount,
                                    //status: row.status,
                                }
                                if (row.extendedPaymentDate) invoice.extendedPaymentDate = inputDateStringToISODateFormat(row.extendedPaymentDate)
                                //if (row.number) invoice.number = row.number
                                //if (typeof row.isProforma === "boolean") invoice.isProforma = row.isProforma
                                if (row.paidAmount) invoice.paidAmount = row.paidAmount
                                if (row.paidDate) invoice.paidDate = inputDateStringToISODateFormat(row.paidDate)
                                return {...invoice}
                            }),
                            chunks = chunkify(invoices, 100)

                        if (invoices?.length !== updateInvoices?.length) {
                            alert("Something went wrong, please refresh the page and try again!")
                            return
                        }

                        if (error) {
                            alert(error)
                            return
                        }

                        for (let i = 0; i < chunks?.length; i++) {
                            const chunk = chunks[i]
                            await sharedFetch(`admin/finance/promissory-notes/${id}/invoices`, {
                                method: 'PUT',
                                headers, body: JSON.stringify({invoices: chunk})
                            }).catch(e => {
                                if (e?.message) {
                                    alert("Error updating invoices: " + e.message)
                                } else {
                                    alert("There was an error updating invoices!")
                                }
                                throw e
                            })
                        }
                    }

                    if (deleteInvoices?.length > 0) {
                        const chunks = chunkify(deleteInvoices, 100)
                        for (let i = 0; i < chunks?.length; i++) {
                            const chunk = chunks[i]
                            await sharedFetch(`admin/finance/promissory-notes/${id}/invoices`, {
                                method: 'DELETE',
                                headers, body: JSON.stringify({deleteInvoicesIds: chunk})
                            }).catch(e => {
                                if (e?.message) {
                                    alert("Error deleting invoices: " + e.message)
                                } else {
                                    alert("There was an error deleting invoices!")
                                }
                                throw e
                            })
                        }
                    }
                }

                await start()

                setAddInvoices([])
                setDeleteInvoices([])
                setUpdateInvoices([])
                fetchData(id)
                fetchInvoices(id)
            } catch (e) {

            }


            setIsSubmittingInvoices(false)
        }
    }

    function download(extended, cb) {
        if (extended) {
            const types = ["Odstopna_izjava", "Specifikacija", "Dogovor_o_placilu", "Informativni_izracun"],
                ps = []

            const assigneeName = slugify(serverData?.assignee?.shortName || serverData?.assignee?.name)
            const debtorName = slugify(serverData?.debtor?.shortName || serverData?.debtor?.name)

            for (let i = 0; i < types.length; i++) {
                const t = types[i]
                ps.push(sharedFetch(`finance/promissory-notes/${id}/draft/${t}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                    }
                })
                    .then(response => response.blob())
                    .then(blob => {
                        downloadFile(blob, `${documentNo}_${t}_${assigneeName}_${debtorName}.pdf`)
                    }))
            }

            Promise.all(ps).finally(() => cb && cb())

        } else sharedFetch(`finance/promissory-notes/${id}/draft`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const assigneeName = slugify(serverData?.assignee?.shortName || serverData?.assignee?.name)
                const debtorName = slugify(serverData?.debtor?.shortName || serverData?.debtor?.name)

                downloadFile(blob, `${documentNo}_${assigneeName}_${debtorName}.pdf`)
            })
            .finally(() => cb && cb())

    }

    function downloadUPN(cb) {
        sharedFetch(`admin/finance/promissory-notes/${id}/upn`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then(response => response.blob())
            .then(blob => {
                downloadFile(blob, `upn-${documentNo}.xml`)
                fetchData(id)
            })
            .finally(() => cb && cb())
    }

    function downloadCostsSpecification(cb) {
        sharedFetch(`admin/finance/promissory-notes/${id}/costs-specification`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then(response => response.blob())
            .then(blob => {
                downloadFile(blob, `spec-${documentNo}.pdf`)
                //fetchData(id)
            })
            .finally(() => cb && cb())
    }

    function deleteEntry(e) {
        e.preventDefault()
        alert("This method is not implemented yet!")
        //const ok = window.confirm("Are you sure you wish to delete this entry?")
        /*if (ok) sharedFetch(`promissory-notes/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then(res => {
            goBack()
        });*/
    }

    function onUploadFile({file, type}, _callback) {
        const cb = (ok, error) => {
                if (_callback) _callback(ok, error)
                id && fetchData(id)
            },
            formData = new FormData()
        formData.append("file", file)
        if (id) {
            const headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            }
            sharedFetch(`files/upload`, {
                method: 'POST',
                headers, body: formData
            }).then(data => {
                if (data?.id) {
                    headers['Content-Type'] = 'application/json'
                    const body = JSON.stringify({
                        fileId: data.id,
                        type: type
                    })
                    sharedFetch(`admin/finance/promissory-notes/${id}/documents`, {
                        method: 'POST',
                        headers, body
                    }).then(() => {
                        cb(true)
                    }).catch(e => {
                        cb(false, e.message)
                    })
                } else {
                    cb(false, "Error uploading file")
                }
            }).catch(e => {
                cb(false, e.message)
            })
        } else cb(true)
    }

    function onDownloadFromCloudStorageClick({cloudStoragePath}) {
        return (cb) => {
            sharedFetch(`admin/files?cloudStoragePath=${window.encodeURIComponent(cloudStoragePath)}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const spl = cloudStoragePath?.split('/')
                    downloadFile(blob, spl[spl?.length - 1])
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    } else console.error(e)
                })
                .finally(() => cb && cb())
        }
    }

    function fetchCompanies() {
        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
        }
        return sharedFetch(`admin/organizations/investmentCompanies`, {
            method: 'GET',
            headers
        }).then(data => {
            setCompanies(data)
        }).catch(e => {
            console.error(e)
        })
    }

    return (
        <>
            <div className="my-8">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    <form onSubmit={onSubmit} className="space-y-8">
                        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-4 flex items-center space-x-4"/>
                            <div className="ml-4 mt-4 flex-shrink-0 flex">
                                <div className="flex-shrink-0 self-center flex ml-4">
                                    <Menu as="div" className="relative z-30 inline-block text-left">
                                        <div>
                                            <Menu.Button
                                                className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                                <span className="sr-only">Open options</span>
                                                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {id && (
                                                        <>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <a
                                                                        href={"#/logs?documentation=" + id}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
                                                                            'flex px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        <ViewListIcon
                                                                            className="mr-3 h-5 w-5 text-gray-400"
                                                                            aria-hidden="true"/>
                                                                        <span>View logs</span>
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <a
                                                                        onClick={deleteEntry}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-red-800' : 'text-red-700',
                                                                            'flex px-4 py-2 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        <DocumentRemoveIcon
                                                                            className="mr-3 h-5 w-5 text-red-400"
                                                                            aria-hidden="true"/>
                                                                        <span>Cancel</span>
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </>
                                                    )}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        {/*Alerts and warnings*/}
                        <div className="flex flex-col space-y-4">
                            {/*Critical*/}
                            {criticalLogs?.length > 0 && (
                                <div className="rounded-md bg-red-50 p-4 w-full">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">Critical errors</h3>
                                            <div className="mt-2 text-sm text-red-700">
                                                <ul role="list" className="list-disc space-y-1 pl-5">
                                                    {criticalLogs.map((log, index) => (
                                                        <li key={index}>{log.code}</li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*Warnings*/}
                            {warningLogs?.length > 0 && (
                                <div className="rounded-md bg-yellow-50 p-4 w-full">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                                            <div className="mt-2 text-sm text-yellow-700">
                                                <ul role="list" className="list-disc space-y-1 pl-5">
                                                    {warningLogs.map((log, index) => (<li key={index}>{log.code}</li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*Info*/}
                            {infoLogs?.length > 0 && (
                                <div className="rounded-md bg-blue-50 p-4 w-full">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <InformationCircleIcon className="h-5 w-5 text-blue-400"
                                                                   aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-blue-800">Attention needed</h3>
                                            <div className="mt-2 text-sm text-blue-700">
                                                <ul role="list" className="list-disc space-y-1 pl-5">
                                                    {infoLogs.map((log, index) => (<li key={index}>{log.code}</li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                        <section className="bg-white shadow sm:rounded-lg space-y-8">
                            <div className="space-y-8 divide-y divide-gray-200 px-4 py-5 sm:p-6 sm:space-y-5">
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                        <label htmlFor="status"
                                               className={classNames(
                                                   "block text-sm font-medium sm:mt-px sm:pt-2 text-gray-700",
                                                   /*!id ? "text-gray-300" : */"text-gray-700"
                                               )}>
                                            Status
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <select
                                                id="status"
                                                name="status"
                                                disabled
                                                onChange={e => setStatus(e.target.value)}
                                                value={status}
                                                className={classNames(
                                                    "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                    /*!id ? "border-gray-300 bg-gray-50 text-gray-600" : */"border-gray-300 bg-gray-50 text-gray-600"
                                                )}
                                            >
                                                <option value="" disabled>Status</option>
                                                {Object.keys(PROMISSORY_NOTE_STATUS).map(key => (
                                                    <option key={key} value={key}>{PROMISSORY_NOTE_STATUS[key]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="type"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Type
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <select
                                                id="type"
                                                name="type"
                                                disabled={!!serverData?.type}
                                                onChange={e => setType(e.target.value)}
                                                value={type}
                                                className={classNames(
                                                    "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                    !!serverData?.type ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                )}
                                            >
                                                <option key={"-1"} disabled value="">Set type
                                                </option>
                                                {Object.keys(TYPE).map((key, index) => (
                                                    <option key={index}
                                                            value={key}>{TYPE[key]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="documentNo"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Number
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                name="documentNo"
                                                id="documentNo"
                                                pattern="[\d-]{1,15}"
                                                placeholder={!id ? "Auto-generated" : ""}
                                                disabled={!canEdit}
                                                value={documentNo}
                                                onChange={e => setDocumentNo(e.target.value ? validReferenceNumber(e.target.value) ? e.target.value : documentNo : "")}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="date"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Date
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="date"
                                                name="date"
                                                id="date"
                                                disabled={!canEdit}
                                                value={date}
                                                onChange={e => setDate(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="transactionDate"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Transaction date
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="date"
                                                name="transactionDate"
                                                id="transactionDate"
                                                disabled={!canEdit}
                                                value={transactionDate}
                                                onChange={e => setTransactionDate(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {id && (
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <div
                                                className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Files
                                            </div>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm text-gray-900">
                                                <div className="mb-3">
                                                    <button
                                                        type="button"
                                                        onClick={() => setUploadFileDialogOpen(true)}
                                                        className="inline-flex items-center rounded border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                                    >
                                                        Upload
                                                        <UploadIcon className="-mr-0.5 ml-2 h-4 w-4"
                                                                    aria-hidden="true"/>
                                                    </button>
                                                </div>

                                                <ul role="list"
                                                    className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                                    {(factoringPricing || serverData?.status !== PROMISSORY_NOTE_STATUS_KEY.DRAFT) && (
                                                        <>
                                                            <ExtendedFileItem title={"Draft Contract"}
                                                                              text={"Created on request"}
                                                                              onDownload={download}/>
                                                        </>
                                                    )}
                                                    {documents?.length > 0 && documents.map((document, index) => (
                                                        <FileItem key={index}
                                                                  archived={!!document.overridden}
                                                                  title={documentTypes[document.type] || "Document"}
                                                                  text={"Uploaded " + fireTimestampToDateTimeString(document.createdAt)}
                                                                  onDownload={onDownloadFromCloudStorageClick(document)}/>
                                                    ))}
                                                    {serverData?.status === PROMISSORY_NOTE_STATUS_KEY.FINANCING_IN_PROGRESS && (
                                                        <FileItem title={"UPN.xml"} text={"Created on request"}
                                                                  onDownload={downloadUPN}/>
                                                    )}
                                                    {serverData?.status === PROMISSORY_NOTE_STATUS_KEY.PAID && (
                                                        <FileItem title={"Costs Specification"}
                                                                  text={"Created on request"}
                                                                  onDownload={downloadCostsSpecification}/>
                                                    )}
                                                    {result?.results?.length > 0 && result?.results.map((document, index) => (
                                                        <FileItem key={index}
                                                                  archived={!!document.overridden}
                                                                  title={"Bill " + document.number}
                                                                  text={"Created on request — Issued " + fireTimestampToDateTimeString(document.issuedDate)}
                                                                  onDownload={(cb) => downloadBilling(document.id, cb)}/>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {id && (
                                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-between">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={openSendEmailDialog}
                                            disabled={isSubmitting || isSubmittingInvoices}
                                            className={classNames(
                                                "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                (!isSubmitting || isSubmittingInvoices) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                            )}
                                        >
                                            Send for signing
                                        </button>
                                        {/*{(id && (serverData.status === PROMISSORY_NOTE_STATUS_KEY.FINANCING_IN_PROGRESS || serverData.status === PROMISSORY_NOTE_STATUS_KEY.FINANCED || serverData.status === PROMISSORY_NOTE_STATUS_KEY.PAID) && (
                                            <button
                                                type="button"
                                                onClick={() => setExpensesDialogOpen(true)}
                                                disabled={isSubmitting || isSubmittingInvoices}
                                                className={classNames(
                                                    "ml-2 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (!isSubmitting || isSubmittingInvoices) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Create bill of expenses
                                            </button>
                                        ))}*/}
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={!updatesAwaiting || isSubmitting || isSubmittingInvoices}
                                        className={classNames(
                                            "ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none",
                                            updatesAwaiting ? "text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm" : "text-gray-300 bg-gray-50 border-gray-200 cursor-default"
                                        )}
                                    >
                                        {isSubmitting ? "Saving..." : "Save"}
                                        {isSubmitting && <Spinner button/>}
                                    </button>
                                </div>
                            )}
                        </section>

                        <section className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg h-fit">
                                <div className="px-4 py-5 sm:px-6 flex items-center">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Debtor</h3>
                                    <div className="flex-grow"/>
                                </div>

                                <PartySelector mayChange={invoiceRows?.length === 0} partyType={"debtor"}
                                               companies={companies}
                                               onSubmit={onChangeParty} serverData={serverData}/>
                            </div>
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg h-fit">
                                <div className="px-4 py-5 sm:px-6 flex items-center">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Assignee</h3>
                                    <div className="flex-grow"/>
                                </div>

                                <PartySelector mayChange={invoiceRows?.length === 0} partyType={"assignee"}
                                               noteType={serverData?.type || null}
                                               companies={companies}
                                               onSubmit={onChangeParty} serverData={serverData}/>

                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    {/*<dl className="sm:divide-y sm:divide-gray-200">
                                        {renderPartyName(assigneeData)}
                                        <div
                                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Bank account
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                                {assigneeData?.bankAccount?.number || "N/A"} ({assigneeData?.bankAccount?.bankName || "N/A"})
                                            </dd>
                                        </div>
                                        <div
                                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Tax
                                                number
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                                {assigneeData?.vatNo || assigneeData?.taxNo || "N/A"}
                                            </dd>
                                        </div>
                                        <div
                                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Address</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                                {formatAddress(assigneeData?.address) || "N/A"}
                                            </dd>
                                        </div>
                                    </dl>*/}
                                </div>
                            </div>
                        </section>

                        {factoringPricing && (
                            <FactoringPricingSummarySection factoringPricing={factoringPricing}/>)}

                        <section className="bg-white shadow sm:rounded-lg">

                            <div className="px-4 py-5 sm:px-6 flex items-center">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Invoices</h3>
                                {serverData?.assignee && serverData?.debtor && (status === PROMISSORY_NOTE_STATUS_KEY.DRAFT || status === PROMISSORY_NOTE_STATUS_KEY.PENDING_APPROVAL) && (
                                    <>
                                        <button
                                            type="button"
                                            onClick={addRow}
                                            className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        >
                                            <PlusSmIconSolid className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                        <label
                                            className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        >
                                            <input ref={importRef} type="file" className="hidden"
                                                   onChange={onImport}/>
                                            <UploadIcon className="h-5 w-5" aria-hidden="true"/>
                                        </label>
                                    </>
                                )}
                            </div>

                            <div className="flex flex-col">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="overflow-hidden border-t border-gray-200">
                                        <div className="flex flex-col">
                                            <div className="align-middle inline-block min-w-full">
                                                <div className="border-b border-gray-200">
                                                    <div className="min-w-full divide-y divide-gray-200">
                                                        <div className="bg-gray-50">
                                                            <div
                                                                className="ml-1 grid grid-cols-table">
                                                                <div className="flex">
                                                                    <div
                                                                        className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Proforma / Inv. No
                                                                    </div>
                                                                    <div
                                                                        className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Invoice Date
                                                                    </div>
                                                                    <div
                                                                        className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Due Date
                                                                    </div>
                                                                    <div
                                                                        className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Extension
                                                                    </div>
                                                                    <div
                                                                        className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                        Amount
                                                                    </div>
                                                                    {(status === PROMISSORY_NOTE_STATUS_KEY.FINANCING_IN_PROGRESS || status === PROMISSORY_NOTE_STATUS_KEY.FINANCED || status === PROMISSORY_NOTE_STATUS_KEY.EXTENDED || status === PROMISSORY_NOTE_STATUS_KEY.DELAYED || status === PROMISSORY_NOTE_STATUS_KEY.IN_RECOVERY) && (
                                                                        <>
                                                                            <div
                                                                                className="flex-1 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Paid At
                                                                            </div>
                                                                            <div
                                                                                className="flex-1 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex justify-between items-center">
                                                                                Paid
                                                                                <button type="button"
                                                                                        className="absolute right-4 bg-gray-200 text-gray-700 px-2 py-0.5 rounded-md text-xs"
                                                                                        onClick={() => setMaxDialogOpen(true)}>MAX</button>
                                                                                {/*<button type="button"
                                                                                        className="absolute right-4 bg-gray-200 text-gray-700 px-2 py-0.5 rounded-md text-xs"
                                                                                        onClick={() => setOnMaxClick(onMaxClick + 1)}>MAX</button>*/}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div/>
                                                            </div>
                                                        </div>
                                                        <DataTable id={id}
                                                                   rows={invoiceRows}
                                                                   originalRowMap={originalInvoiceMap}
                                                                   updateRow={updateRow}
                                                                   deleteRow={deleteRow}
                                                                   status={status}
                                                            /*onMaxClick={onMaxClick}*/
                                                                   minAgreedPaymentDate={today}
                                                                   infoCosts={infoCosts}
                                                                   fetchInfoCosts={fetchInfoCosts}
                                                                   download={downloadStorageFile}
                                                                   mep={debtorMaxExtensionPeriod}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {id && (
                                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                    {invoiceUpdatesAwaiting && (
                                        <span>{[addInvoices?.length > 0 ? "Added " + addInvoices.length + " invoice(s)" : "", updateInvoices?.length > 0 ? "Updated " + updateInvoices.length + " invoice(s)" : "", deleteInvoices?.length > 0 ? "Deleted " + deleteInvoices.length + " invoice(s)" : ""].filter(it => !!it).join(", ")}</span>
                                    )}
                                    <button
                                        type="button"
                                        onClick={submitInvoices}
                                        disabled={!invoiceUpdatesAwaiting || isSubmitting || isSubmittingInvoices}
                                        className={classNames(
                                            "ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none",
                                            invoiceUpdatesAwaiting ? "text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm" : "text-gray-300 bg-gray-50 border-gray-200 cursor-default"
                                        )}
                                    >
                                        {isSubmittingInvoices ? "Saving..." : "Save"}
                                        {isSubmittingInvoices && <Spinner button/>}
                                    </button>
                                </div>
                            )}
                        </section>

                        {!id && (
                            <div className="flex justify-end items-center">
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Create
                                </button>
                            </div>
                        )}

                    </form>
                )}
            </div>
            <SavingNotification open={isSubmitting}/>
            <MaxDialog open={maxDialogOpen} setOpen={setMaxDialogOpen} defaultPaidAmount={serverData?.openAmount?.value}
                       onSubmit={onMaxDialogSubmit}/>
            <BillOfExpensesDialog open={expensesDialogOpen} setOpen={setExpensesDialogOpen}
                                  lastDocument={result?.results?.length > 0 ? result.results[result.results.length - 1] : null}
                                  onSubmit={onExpensesDialogSubmit}/>
            <EmailNotificationDialog open={emailNotificationDialogOpen} setOpen={setEmailNotificationDialogOpen}
                                     onSubmit={onSendDocumentsForSigning}/>
            <UploadFileDialog open={uploadFileDialogOpen} setOpen={setUploadFileDialogOpen}
                              onSubmit={onUploadFile}/>
        </>
    )
};
